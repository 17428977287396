@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Lato:wght@900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SFProDisplay";
  src: url("./fonts/SFProDisplay-Regular.woff")
      format("woff"),
    url("./fonts/FontsFree-Net-SFProDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.body{
  font-family: "SFProDisplay";
};

.roboto{
  font-family: 'Roboto';
}


.auth-bg{
  background-image: url(./assets/Image.jpg) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.m-a{
  margin-left: auto;
  margin-right: auto;
}

.ml-a{
  margin-left: auto;
}

.w-250{
  width: 250px;
}

.w-150{
  width: 150px;
}

.text-cs{
  font-size: 25px;
  line-height: 29px;
}

.lh-48{
  line-height: 48px;
}

.br50{
  border-radius: 50%;
}

.left-nav li{
  font-weight: 500;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  color: #959595;
  cursor: pointer;
  display: flex;
  margin-bottom: 35px;
  position: relative;
}

.left-nav li.active{
  font-weight: 700;
  color: #E60026;
  transition: color 0.2s linear;
}

/* li.active::before{

  border-top-right-radius: 7px #E60026;;
  border-bottom-right-radius: 7px #E60026;;
  border-left: 10px solid #E60026;
} */

.left-nav li::before{
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0;
  left: 0;
  background: #E60026;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px ;
  transform: translateX(-100%);
  transition: ease-out 0.2s;
  
}

li.active::before{
  transform: none;
}

